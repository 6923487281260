import type { Form } from '@aurora/shared-generated/types/graphql-schema-types';
import { AuthErrorMessage } from '@aurora/shared-types/authentication';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { ErrorOption } from 'react-hook-form/dist/types/errors';
import type { FieldPath } from 'react-hook-form/dist/types/path';
import { useUIDSeed } from 'react-uid';
import { SharedComponent } from '../../../enums';
import clientAuthenticationHelper from '../../../helpers/authentication/ClientAuthenticationHelper';
import FormBuilder from '../../../helpers/form/FormBuilder/FormBuilder';
import {
  FormFeedbackPosition,
  FormFeedbackType
} from '../../../helpers/form/FormFieldFeedback/enums';
import type FormFeedbackTypes from '../../../helpers/form/FormFieldFeedback/form-feedback-types';
import { BannerFormAlertVariant } from '../../common/BannerFormAlert/BannerFormAlert';
import { LoadingButtonVariant } from '../../common/Button/enums';
import { ToastAlertVariant, ToastVariant } from '../../common/ToastAlert/enums';
import type ToastProps from '../../common/ToastAlert/ToastAlertProps';
import useToasts from '../../context/ToastContext/useToasts';
import { FormActionButtonsPosition } from '../../form/enums';
import InputEditForm from '../../form/InputEditForm/InputEditForm';
import type { FormSpec } from '../../form/types';
import useDateTime from '../../useDateTime';
import useUserManagementProperties from '../../users/useUserManagementProperties';
import useTranslation from '../../useTranslation';
import formSchema from './LoginForm.form.json';
import localStyle from './LoginForm.module.pcss';
import TenantContext from '../../context/TenantContext';
import useQueryWithTracing from '../../useQueryWithTracing';
import type {
  AuthProvidersQuery,
  AuthProvidersQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import authProvidersQuery from '../AuthProviders.query.graphql';

const log = getLog(module);

interface FormData {
  username: string;
  password: string;
  keepMeSignedIn: boolean;
  vktEnabled: boolean;
}

interface Props {
  /**
   * Callback function after login.
   *
   * @callback
   */
  onLogin: () => void;

  /**
   * Whether ReCaptcha should be used.
   */
  useReCaptcha?: boolean;
  /**
   * Whether allow backdoor normal sign-on.
   */
  ssoAllowNormalSignon?: boolean;
}

/**
 * Displays a login form.
 *
 * @author Dolan Halbrook, Adam Ayres, Willi Hyde, Corey Aing
 */
const LoginForm: React.FC<React.PropsWithChildren<Props>> = ({
  onLogin,
  useReCaptcha = true,
  children,
  ssoAllowNormalSignon = false
}) => {
  const i18n = useTranslation(SharedComponent.LOGIN_FORM);
  const { formatMessage, loading: textLoading } = i18n;
  const cx = useClassNameMapper(localStyle);
  const uidSeed = useUIDSeed();
  const { addToast } = useToasts();
  const { formatAbsoluteDateTime } = useDateTime();
  const tenant = useContext(TenantContext);
  const {
    publicConfig: { multiAuthEnabled }
  } = tenant;
  const { data: userManagementData, loading: userManagementLoading } = useUserManagementProperties(
    true,
    false
  );

  const { data: authProvidersData, loading: authProvidersLoading } = useQueryWithTracing<
    AuthProvidersQuery,
    AuthProvidersQueryVariables
  >(module, authProvidersQuery, {
    skip: !multiAuthEnabled
  });

  if (textLoading || userManagementLoading || authProvidersLoading) {
    return null;
  }

  const {
    userManagementProperties: { rememberPassword }
  } = userManagementData?.community || { userManagementProperties: { rememberPassword: false } };

  const [localAuthProvider] = authProvidersData?.authProviders ?? [];

  /**
   * Renders failure toast messages
   * @param alertVariant The variant for the alert message
   * @param title Title to be displayed
   * @param body Toast body
   * @param autohide whether the toast should be hidden automatically after certain time interval
   */
  function renderToast(
    alertVariant: ToastAlertVariant,
    title: string,
    body: string | React.FC<React.PropsWithChildren<unknown>>,
    autohide = false
  ): void {
    const toastVariant = ToastVariant.FLYOUT;
    const toastProps: ToastProps = {
      id: uidSeed(title),
      toastVariant,
      alertVariant,
      title,
      autohide,
      message: body
    };
    addToast(toastProps);
  }

  async function onSubmit(
    data: FormData,
    action: string,
    event: React.FormEvent<HTMLFormElement>,
    setError: (name: FieldPath<FormData>, error: ErrorOption) => void,
    setFormFeedback: (feedback: FormFeedbackTypes.FormFeedback) => void
  ): Promise<void> {
    event.preventDefault();

    const { username, password, keepMeSignedIn, vktEnabled } = data;
    if (vktEnabled) {
      log.warn('Login was attempted with honeypot checked on %s', new Date().toUTCString());
      return;
    }

    const {
      success,
      message: errorType,
      messageArgs
    } = (await clientAuthenticationHelper.login(username, password, keepMeSignedIn)) || {};
    if (success) {
      onLogin();
    } else {
      log.info('Login error with type: %s', errorType);

      if (errorType) {
        if (errorType === AuthErrorMessage.banned) {
          const { banReason, banDurationLeft } = messageArgs;
          const banEndDateTime = formatAbsoluteDateTime(+banDurationLeft);
          const banPublicReason: string = banReason as string;
          renderToast(
            ToastAlertVariant.DANGER,
            formatMessage('LoginForm.banned.error.title'),
            () => {
              return (
                <>
                  <div data-testid={`BanTime.${banDurationLeft == -1 ? 'Permanent' : 'Temporary'}`}>
                    {banDurationLeft == -1
                      ? formatMessage('LoginForm.banned.error.description.permanent')
                      : formatMessage('LoginForm.banned.error.description.temporary', {
                          banEndDateTime
                        })}
                  </div>
                  {banReason && (
                    <div data-testid="PublicBanReason">
                      {formatMessage('LoginForm.banned.error.publicReason', {
                        banPublicReason
                      })}
                    </div>
                  )}
                </>
              );
            },
            true
          );
        } else {
          setFormFeedback({
            message: formatMessage(`LoginForm.authentication.${errorType}.error`),
            variant: {
              type: FormFeedbackType.BANNER,
              alertVariant: BannerFormAlertVariant.DANGER
            },
            position: FormFeedbackPosition.TOP
          });
        }
      }
    }
  }

  const formSpec: FormSpec<FormData> = new FormBuilder<FormData>(
    'LoginForm',
    i18n,
    {
      schema: formSchema as Form,
      cx
    },
    {
      useReCaptcha,
      ...(multiAuthEnabled &&
        !ssoAllowNormalSignon &&
        !localAuthProvider?.enabled && { formClassName: 'd-none' })
    }
  )
    .addTextInputField({
      name: 'username',
      validations: {
        required: true
      },
      attributes: {
        autoComplete: 'username'
      },
      focus: true,
      defaultValue: ''
    })
    .addPasswordField({
      name: 'password',
      validations: {
        required: true
      },
      passwordViewable: true,
      defaultValue: ''
    })
    .addCheckField({
      name: 'keepMeSignedIn',
      formGroupSpec: {
        showInfo: true
      },
      defaultValue: rememberPassword
    })
    // NOTE: this is a honeypot field
    .addCheckField({
      name: 'vktEnabled',
      defaultValue: false,
      className: 'd-none'
    })
    .setActionButtonsPosition(FormActionButtonsPosition.STRETCH)
    .addSubmitAction({
      buttonType: LoadingButtonVariant.LOADING_BUTTON,
      size: 'lg',
      title: formatMessage('LoginForm.submit')
    })
    .build();

  return (
    <InputEditForm<FormData> formSpec={formSpec} onSubmit={onSubmit}>
      {multiAuthEnabled && (ssoAllowNormalSignon || localAuthProvider.enabled)
        ? children
        : !multiAuthEnabled
        ? children
        : null}
    </InputEditForm>
  );
};

export default LoginForm;
