import authPropertiesQuery from '@aurora/shared-client/components/authentication/AuthProperties.query.graphql';
import LoginForm from '@aurora/shared-client/components/authentication/LoginForm/LoginForm';
import useAuthTransition from '@aurora/shared-client/components/authentication/useAuthTransition';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import Panel, { PanelBody, PanelHeader } from '@aurora/shared-client/components/common/Panel/Panel';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import {
  EndUserComponent,
  EndUserPages,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import React, { useContext, useId, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { AuthFlow } from '@aurora/shared-client/types/enums';
import type { WidgetFC, WidgetProps } from '../../common/Widget/types';
import useTranslation from '../../useTranslation';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useEndUserNextPage from '@aurora/shared-client/routes/useEndUserNextPage';
import type {
  AuthPropertiesQuery,
  AuthPropertiesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import dynamic from 'next/dynamic';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import localStyle from './LoginWidget.module.pcss';

const MultiAuthLoginForm = dynamic(
  () =>
    import('@aurora/shared-client/components/authentication/MultiAuthLoginForm/MultiAuthLoginForm')
);

interface Props extends WidgetProps {
  /**
   * Whether ReCaptcha should be used.
   */
  useReCaptcha?: boolean;
}

/**
 * Login Widget Component used for Login page.
 *
 * @constructor
 *
 * @author Medha Smriti, Willi Hyde
 */
const LoginWidget: WidgetFC<Props> = ({ useReCaptcha }) => {
  const cx = useClassNameMapper(localStyle);
  const { Link, router } = useEndUserRoutes();
  const tenant = useContext(TenantContext);
  const { nextRoute } = useEndUserNextPage();
  const inviteToken = router.getUnwrappedQueryParam(EndUserQueryParams.INVITE_TOKEN);
  const nextAuthFlowHandler = router.getUnwrappedQueryParam(EndUserQueryParams.NEXT_AUTH_FLOW);
  const handleAuthTransition = useAuthTransition(nextRoute);
  const {
    formatMessage,
    FormattedMessage,
    loading: textLoading
  } = useTranslation(EndUserComponent.LOGIN_WIDGET);
  const { canAccess, canRegister } = useContext(AppContext);
  const { ssoAllowNormalSignon, multiAuthEnabled } = tenant.publicConfig;
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false);

  const { data: authPropertiesData, loading: authPropertiesLoading } = useQueryWithTracing<
    AuthPropertiesQuery,
    AuthPropertiesQueryVariables
  >(module, authPropertiesQuery, {
    skip: !multiAuthEnabled
  });

  const uid = useId();
  if (textLoading || authPropertiesLoading) {
    return null;
  }

  function doTransition() {
    if (!nextAuthFlowHandler) {
      handleAuthTransition(true);
    } else {
      // This will redirect to oauthHandshakemiddleware to continue with authorization flow and get the code and redirect to oauth client
      window.location.href = UrlHelper.sanitize(
        UrlHelper.getFullyQualifiedUrlForPath(tenant, nextAuthFlowHandler)
      );
    }
  }

  function renderNav(authFlow: AuthFlow, pageRoute: EndUserPages): React.ReactElement {
    return (
      <div className={cx('lia-g-auth-nav-section text-center')}>
        <FormattedMessage
          id={`nav.text.${authFlow}`}
          values={{
            link: function renderChunks(chunks): React.ReactNode {
              return (
                <Link
                  route={pageRoute}
                  legacyBehavior={true}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(inviteToken &&
                    pageRoute === EndUserPages.RegistrationPage && {
                      query: {
                        [EndUserQueryParams.INVITE_TOKEN]: inviteToken
                      }
                    })}
                >
                  <a>{chunks}</a>
                </Link>
              );
            }
          }}
        />
      </div>
    );
  }

  /**
   * Renders a nav to return multi auth login form
   */
  const renderMultiAuthOptionsNav = (): React.ReactElement => {
    return (
      <div className={cx('lia-g-auth-nav-section text-center lia-text-link-wrap')}>
        <FormattedMessage
          id={'nav.text.multiAuthLogin'}
          values={{
            button: chunks => (
              <Button
                variant={ButtonVariant.LINK}
                className={cx('lia-g-link-btn')}
                onClick={() => setShowLoginForm(false)}
              >
                {chunks}
              </Button>
            )
          }}
        />
      </div>
    );
  };

  return (
    <main className={cx('lia-g-auth-wrapper')}>
      <Panel className={cx('lia-g-auth-panel')} data-testid={uid}>
        <PanelHeader className={cx('lia-g-auth-panel-header text-center')}>
          <h1 className={cx('lia-g-auth-panel-h1')}>{formatMessage('title')}</h1>
          {multiAuthEnabled && (
            <span className={cx('small order-3 w-100 lia-g-mt-5')}>
              {authPropertiesData?.community?.authProperties?.multiauthWelcomeMessage}
            </span>
          )}
        </PanelHeader>
        <PanelBody className={cx('lia-g-auth-panel-body')}>
          {multiAuthEnabled && !ssoAllowNormalSignon && !showLoginForm ? (
            <MultiAuthLoginForm
              onLogin={() => doTransition()}
              setAuthFlow={() => AuthFlow.MULTI_AUTH_LOGIN}
              ssoAllowNormalSignon={ssoAllowNormalSignon}
              hideLoginFormModal={true}
              onShowLoginForm={() => setShowLoginForm(true)}
            >
              {renderNav(AuthFlow.FORGOT_PASSWORD, EndUserPages.ForgotPasswordPage)}
              {canAccess &&
                canRegister &&
                renderNav(AuthFlow.REGISTRATION, EndUserPages.RegistrationPage)}
            </MultiAuthLoginForm>
          ) : (
            <>
              <LoginForm
                onLogin={() => doTransition()}
                useReCaptcha={useReCaptcha}
                ssoAllowNormalSignon={ssoAllowNormalSignon}
              >
                {renderNav(AuthFlow.FORGOT_PASSWORD, EndUserPages.ForgotPasswordPage)}
                {canAccess &&
                  canRegister &&
                  renderNav(AuthFlow.REGISTRATION, EndUserPages.RegistrationPage)}
              </LoginForm>
              {multiAuthEnabled && renderMultiAuthOptionsNav()}
            </>
          )}
        </PanelBody>
      </Panel>
    </main>
  );
};

export default LoginWidget;
